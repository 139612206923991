<template>
  <div class="detail">
    <MobileTable
      v-model:selection="store.state.voting.selectedDetails"
      :label="$route.name"
      :columns="detail.columns"
      :data="store.state.voting.details"
    />
    <DesktopTable
      v-model:selection="store.state.voting.selectedDetails"
      :label="$route.name"
      :columns="detail.columns"
      :data="store.state.voting.details"
    />
    <Actions :items="detail.actions" />
    <BasicModal v-model:visible="detail.showDialog" @hide="onHideModal">
      <template v-slot:dialog>
        <BasicTitle title="Detalle de la Votación" />
        <BasicSubtitle
          subtitle="Los campos señalados con (*) son obligatorios."
        />
        <div class="container-form">
          <BasicLabel label="Título *" />
          <BorderInput
            v-model="detail.data.title"
            label="Título *"
            @keyup.enter="onSave"
            :disabled="detail.disabled"
          />
          <FormError :show="detail.rules.title" />
          <BasicLabel label="Descripción *" />
          <OutlinedTextArea
            v-model="detail.data.description"
            :disabled="detail.disabled"
          />
          <FormError
            :show="detail.rules.description"
            message="Ingrese una descripción"
          />

          <div
            v-if="store.state.openMode !== openMode.DETAIL"
            class="container-form"
          >
            <div class="check">
              <label class="check-label" for="multiple"
                >Permitir múltiples opciones</label
              >
              <Checkbox
                id="multiple"
                value=""
                v-model="detail.data.multiple"
                :disabled="detail.disabled"
              />
            </div>
            <FormError />
            <BasicLabel label="Opciones *" />
            <BorderInput
              v-for="(item, idx) in detail.data.options"
              :key="idx"
              v-model="item.name"
              label="Ingrese una opción"
              :disabled="detail.disabled"
            />
            <FormError
              :show="detail.rules.options"
              message="Ingrese las opciones"
            />
            <SecondaryButton
              v-if="!detail.disabled"
              label="Agregar opción"
              :click="onAddOption"
            />
          </div>
          <div v-else>
            <BasicSubtitle subtitle="Resultados" />
            <FormError />
            <div
              v-for="item in detail.data.options"
              :key="item.id"
              class="result"
            >
              <BasicLabel :label="item.name" width="100%" align="left" />
              <BasicLabel
                :label="item.value ? item.value : 0"
                width="100%"
                align="right"
              />
            </div>
          </div>
          <FormError />
          <FormError />
          <PrimaryButton
            v-if="!detail.disabled"
            label="Guardar"
            :click="onSave"
          />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import FormError from "../../widgets/error/FormError";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import SecondaryButton from "../../widgets/button/SecondaryButton";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    FormError,
    PrimaryButton,
    SecondaryButton,
    BasicModal,
    OutlinedTextArea,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      detail.disabled = false;
      detail.showDialog = true;
    };
    const onModify = () => {
      if (store.state.voting.selectedDetails.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (store.state.voting.selectedVotings[0].statusId === 2) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "No se puede modificar la votación porque ya ha iniciado!",
          life: 5000,
        });
      } else if (store.state.voting.selectedDetails[0].votes.length) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "No se puede modificar el registro porque existen votos!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        const selected = store.state.voting.selectedDetails[0];
        detail.data.id = selected.id;
        detail.data.votingId = selected.votingId;
        detail.data.title = selected.title;
        detail.data.description = selected.description;
        detail.data.multiple = selected.multiple ? "true" : "";
        detail.data.options = selected.options.map((item) => ({
          name: item.name,
        }));
        detail.disabled = false;
        detail.showDialog = true;
      }
    };
    const onDetail = () => {
      if (store.state.voting.selectedDetails.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (!store.state.voting.selectedDetails[0].votes.length) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "No existen votos para esta votación!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.DETAIL);
        const selected = store.state.voting.selectedDetails[0];
        detail.data.id = selected.id;
        detail.data.votingId = selected.votingId;
        detail.data.title = selected.title;
        detail.data.description = selected.description;
        detail.data.options = selected.options;
        selected.votes.forEach((item) => {
          const arr = item.value.split(",");
          arr.forEach((el) => {
            const option = detail.data.options.find(
              (option) => option.id === +el
            );
            if (option) {
              detail.data.options.splice(
                detail.data.options.indexOf(option),
                1
              );
              if (!option.value) {
                option.value = 1;
              } else {
                option.value += 1;
              }
              detail.data.options.push(option);
            }
          });
        });
        detail.data.options = detail.data.options.sort((a, b) => {
          a.name < b.name;
        });
        detail.disabled = true;
        detail.showDialog = true;
      }
    };

    const detail = reactive({
      disabled: false,
      showDialog: false,
      data: {
        id: "",
        votingId: store.state.voting.selectedVotings[0].id,
        title: "",
        description: "",
        multiple: "",
        options: [{ name: "Sí" }, { name: "No" }],
      },
      rules: {
        title: false,
        description: false,
        options: false,
      },
      columns: [
        {
          field: "title",
          header: "Título",
          sort: true,
          center: true,
        },
        {
          field: "description",
          header: "Descripción",
          sort: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/detalle/asociar"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/detalle/asociar"),
        },
        {
          name: "Resultados",
          action: onDetail,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/detalle/asociar"),
        },
      ],
    });

    const validate = () => {
      let valid = true;

      if (!detail.data.title) {
        detail.rules.title = true;
        valid = false;
      }
      if (!detail.data.description) {
        detail.rules.description = true;
        valid = false;
      }
      if (
        !detail.data.options.length ||
        !detail.data.options.filter((item) => item.name).length
      ) {
        detail.rules.options = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);
      const request = {
        id: detail.data.id,
        votingId: detail.data.votingId,
        title: detail.data.title,
        description: detail.data.description,
        multiple: detail.data.multiple ? true : false,
        options: detail.data.options.filter((item) => item.name),
      };
      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.votingActions.updateDetail,
          request
        );
      } else {
        response = await store.dispatch(
          actions.votingActions.createDetail,
          request
        );
      }
      if (response.ok) {
        store.commit("setSelectedDetails", []);
        detail.showDialog = false;
        detail.data.title = "";
        detail.data.description = "";
        detail.data.multiple = "";
        detail.data.options = [{ name: "Sí" }, { name: "No" }];
        await store.dispatch(actions.votingActions.findAllDetail, {
          votingId: store.state.voting.selectedVotings[0].id,
        });
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const onAddOption = () => {
      detail.data.options.push({ name: "" });
    };

    const onHideModal = async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedDetails", []);
      detail.showDialog = false;
      detail.data.title = "";
      detail.data.description = "";
      detail.data.multiple = "";
      detail.data.options = [{ name: "Sí" }, { name: "No" }];
      await store.dispatch(actions.votingActions.findAllDetail, {
        votingId: store.state.voting.selectedVotings[0].id,
      });
      store.commit("setLoading", false);
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedDetails", []);
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.votingActions.findAllDetail, {
        votingId: store.state.voting.selectedVotings[0].id,
      });
      if (store.state.openMode === openMode.CREATE) detail.showDialog = true;
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (detail.data.title) detail.rules.title = false;
      if (detail.data.description) detail.rules.description = false;
      if (detail.data.options.filter((item) => item.name).length)
        detail.rules.options = false;
    });

    return { store, detail, onSave, onAddOption, openMode, onHideModal };
  },
};
</script>

<style scoped>
.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.check-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
.result {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
